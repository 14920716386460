import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import PrimaryHeading from "../../components/common/headings/PrimaryHeading";
import PrimaryButton from "../../components/common/buttons/PrimaryButton";
import PrimarySearch from "../../components/common/inputs/PrimarySearch";
import PrimaryTable from "../../components/common/table/PrimaryTable";
import ProductInfoCard from "../../components/product/ProductInfoCard";
import NavLinks from "../../components/common/navbar/PrimaryNavbar";
import CustomerInfo from "../../components/customer/CustomerInfo";
import ReviewCard from "../../components/reviews/ReviewCard";
import SpinnerLoader from "../../components/common/loader/SpinnerLoader";
import { getQuestionsList } from "../../services/QuestionsServices";
import Pagination from "../../components/common/pagination";
import { capitalizeString } from "../../utils/string";

let timeout = null;

const Questions = () => {
	const [questions, setQuestions] = useState([]);
	const [loading, setLoading] = useState(true);
	const  { search, pathname } = useLocation();
	const history = useNavigate();
	const [search_value, setSearchValue] = useState("");
	const [current_page, setCurrentPage] = useState(1);
	const params = new URLSearchParams(window?.location?.search);
	let type = search.slice(6);

	useEffect(() => {
		let status = params?.get("type");
		let pageParam = params?.get('page') || 1;
		let searchParam = params?.get('q') || undefined;
		setSearchValue(searchParam || '');
		setCurrentPage(pageParam);
		switch (status) {
			case "published":
				status = 1;
				break;
			case "answered":
				status = 5;
				break;
			case "unanswered":
				status = 3;
				break;
			case "archived":
				status = 4;
				break;
			default:
				status = 0;
		}
		getQuestionsList(status, pageParam, searchParam )
			.then((res) => setQuestions(res.data))
			.catch((err) => {
				console.log(err);
			})
			.finally((res) => setLoading(false));
	}, [search]);

	const navLinks = [
		{
			name: "Published",
			link: "published",
		},
		{
			name: "Answered",
			link: "answered",
		},
		{
			name: "Unanswered",
			link: "unanswered",
		},
		{
			name: "Archived",
			link: "archived",
		},
	];

	const columns = [
		{
			name: "Product",
			selector: (row) => row.product,
			width: "480px",
		},
		{
			name: "Customer",
			selector: (row) => row.customer,
			width: "300px",
		},
		{
			name: "Questions",
			selector: (row) => row.questions,
			width: "470px",
		},
		{
			name: "Status",
			selector: (row) => row.status,
		},
	];

	const data = questions?.data?.map((item, index) => {
		const {
			customer_name,
			customer_email,
			question,
			status,
			product,
			id,
			created_at,
		} = item;

		return {
			product: (
				<ProductInfoCard
					cursor="cursor-pointer"
					id={product?.id}
					question_id={id}
					img={product?.image}
					title={product?.title}
					handleClick={() => handleClick(id)}
				/>
			),
			customer: (
				<CustomerInfo
					cursor="cursor-pointer"
					name={customer_name ? capitalizeString(customer_name) : " "}
					email={customer_email ? customer_email : " "}
					handleClick={() => handleClick(id)}
				/>
			),
			questions: (
				<ReviewCard
					cursor="cursor-pointer"
					time={created_at}
					description={question ? question : ""}
					handleClick={() => handleClick(id)}
					type={"question"}
				/>
			),
			status: (
				<div
					className={`rounded cursor-pointer ${
						item.status.toLowerCase() == "unpublished" ||
						item.status.toLowerCase() == "archived" ||
						item.status.toLowerCase() == "unanswered"
							? "bg-[#F3EFDA] text-[#9A7800]"
							: "bg-[#E4F5EB] text-[#00933D]"
					}  text-[13px] p-1 bg-[#F3EFDA] ml-1`}
					onClick={() => handleClick(id)}
				>
					{status ? status : " "}
				</div>
			),
		};
	});

	const handleClick = (id, product) => {
		history(`/questions/${id}?type=${type}`);
	};

	const handleChange = (e) => {
		const { value } = e.target;
		setSearchValue(value);

		if (timeout) {
			clearTimeout(timeout);
		}
		timeout = setTimeout(() => {
			if(value.trim().length > 0) {
				params.set("q",value?.trim());
			}
			else {
				params.delete('q');
			}
			params.delete('page');
			history(pathname + "?" + params);
		}, 1000);
	};

	const handlePageClick = ({ selected }) => {
		const page = selected + 1;
		params.set("page",page);
		history(pathname + "?" + params);
	};

	return (
		<div className="">
			<PrimaryHeading heading="Questions" />
			<div className="bg-white mt-4 rounded body-shadow">
				<NavLinks links={navLinks} />
				<div className="p-6">
					<div className="flex flex-col lg:flex-row justify-between">
						<div className="w-full mb-3 lg:mb-0">
							<PrimarySearch
								placeholder="Search Questions"
								handleChange={handleChange}
								value={search_value}
							/>
						</div>
					</div>
					<div className="mt-6">
						{loading ? (
							<SpinnerLoader />
						) : (
							<PrimaryTable
								columns={columns}
								data={data}
								// selectable
								rowUrl={`/questions`}
								pointerOnHover
							/>
						)}
					</div>

					{questions?.last_page && questions?.last_page > 1 ? (
						<Pagination
							pageCount={questions?.last_page}
							handlePageClick={handlePageClick}
							current_page={current_page}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};
export default Questions;
